<template>
  <v-dialog v-model="showingModal">
    <v-card>
      <v-data-table
      :headers="tableColumns"
      :items="phones"
      :page="current_page"
      :loading="loading"
      
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      
      >
        <template #[`item.phone`]="{item}">
          <div class="d-flex justify-center align-center">
            <span>{{ item.phone }}</span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                class="ml-0" 
                :color="item.has_whatsapp === true 
                ? 'success' 
                : item.has_whatsapp === false 
                ? 'error' : 'grey'" 
                dense v-bind="attrs" v-on="on"
                >
                  {{ icons.mdiWhatsapp}}
                </v-icon>
              </template>
              <span>
                  {{ item.has_whatsapp === true 
                    ? `Tiene WhatsApp. Fecha confirmación: ${item.whatsapp_validation ? item.whatsapp_validation : ' - '}` 
                    : item.has_whatsapp === false 
                    ? `No tiene WhatsApp. Fecha confirmación: ${item.whatsapp_validation ? item.whatsapp_validation : ' - '}`
                    : 'Sin datos de confirmación' 
                  }}
              </span>
            </v-tooltip>
          </div>
        </template>

        <template #[`item.correct`]="{item}">
          <div class="d-flex justify-center align-center">
            <span>
              {{ item.correct === true ? 'Confirmado' : item.correct === false ? 'Dado de baja' : 'Sin datos de confirmación' }}
            </span>
            <v-icon class="ml-0" :color="item.correct === true ? 'success' : item.correct === false ? 'error' : 'warning'" dense>
              {{ item.correct === true ? icons.mdiCheckCircleOutline : item.correct === false ? icons.mdiCloseCircleOutline : icons.mdiInformationOutline}}
            </v-icon>
          </div>
        </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="close()">
        CERRAR
      </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import {
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiInformationOutline,
  mdiWhatsapp
} from "@mdi/js"

export default {
  data: () => {
    return {
      phones: [],
      current_page: 1,
      showingModal: false,
      page_size: 10,
    }
  },
  methods: {
    async openModal(contactPhones) {
        // console.log(item)
      this.phones = contactPhones
      this.totalListTable = contactPhones.length
      this.showingModal = true
    },
  close(){
    this.phones = []
    this.current_page = 1
    this.page_size = 10
    this.totalListTable = 0
    this.showingModal = false
  }
  },
  setup() {
      const loading = ref(false)
      const totalListTable = ref(0)

      const tableColumns = [
        { text: 'Número', value: 'phone', align: 'center', sortable: false},
        { text: 'Estado', value: 'correct', align: 'center', sortable: false },
      ]

      return {
        loading,
        totalListTable,
        tableColumns,
        icons: {
          mdiCheckCircleOutline,
          mdiCloseCircleOutline,
          mdiInformationOutline,
          mdiWhatsapp
        }
      }
    }
}
</script>